import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import Img from 'components/Img'
import QuestionHelper from 'components/QuestionHelper'
import Row from 'components/Row'
import { SITE_NAME } from 'constants/misc'
import { AlertTriangle } from 'react-feather'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { iconErrorMap } from 'theme/assets'

import { PendingModalContainer } from '.'

export enum PendingModalError {
  TOKEN_APPROVAL_ERROR,
  PERMIT_ERROR,
  CONFIRMATION_ERROR,
  WRAP_ERROR,
  CONVERT_ERROR = 4,
}

interface ErrorModalContentProps {
  errorType: PendingModalError
  onRetry: () => void
}

function getErrorContent(errorType: PendingModalError) {
  switch (errorType) {
    case PendingModalError.TOKEN_APPROVAL_ERROR:
      return {
        title: <Trans>Token approval failed</Trans>,
        label: <Trans>Why are approvals required?</Trans>,
        tooltipText: (
          <Trans>
            This provides the {SITE_NAME} protocol access to your token for trading. For security, this will expire
            after 30 days.
          </Trans>
        ),
      }
    case PendingModalError.PERMIT_ERROR:
      return {
        title: <Trans>Permit approval failed</Trans>,
        label: <Trans>Why are permits required?</Trans>,
        tooltipText: (
          <Trans>Permit2 allows token approvals to be shared and managed across different applications.</Trans>
        ),
      }
    case PendingModalError.CONFIRMATION_ERROR:
      return {
        title: <Trans>Swap failed. Try increasing max slippage.</Trans>,
      }
    case PendingModalError.WRAP_ERROR:
      return {
        title: <Trans>Wrap failed</Trans>,
      }
    case PendingModalError.CONVERT_ERROR:
      return {
        title: <Trans>Conversion failed</Trans>,
      }
  }
}

export function ErrorModalContent({ errorType, onRetry }: ErrorModalContentProps) {
  const theme = useTheme()

  const { title, label, tooltipText } = getErrorContent(errorType)

  return (
    <PendingModalContainer gap="lg">
      <AlertTriangle data-testid="pending-modal-failure-icon" strokeWidth={1} color={theme.critical} size="48px" />
      <ColumnCenter gap="md">
        <ThemedText.HeadlineSmall>{title}</ThemedText.HeadlineSmall>
        <Row justify="center">
          {label && <ThemedText.BodySmall color="neutral2">{label}</ThemedText.BodySmall>}
          {tooltipText && <QuestionHelper text={tooltipText} />}
        </Row>
        <Img src={iconErrorMap} width={227} />
      </ColumnCenter>
      <Row justify="center">
        <ButtonPrimary marginX="24px" marginBottom="16px" onClick={onRetry}>
          <Trans>Retry</Trans>
        </ButtonPrimary>
      </Row>
    </PendingModalContainer>
  )
}
