/* eslint-disable import/no-unused-modules */
import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'

import store from '../../state/index'

const BTTC_GRAPH_URL = `${process.env.REACT_APP_V2_GRAPH_URL_BTTC}`
const FTM_GRAPH_URL = `${process.env.REACT_APP_V2_GRAPH_URL_FTM}`
const EON_GRAPH_URL = `${process.env.REACT_APP_V2_GRAPH_URL_EON}`
const BERA_TESTNET_GRAPH_URL = `${process.env.REACT_APP_V2_GRAPH_URL_BERA_TESTNET}`
const SONIC_TESTNET_GRAPH_URL = ``
const GOAT_TESTNET_GRAPH_URL = `${process.env.REACT_APP_V2_GRAPH_URL_GOAT_TESTNET}`

export const V2_SUBGRAPH_URL_BY_CHAIN: { [chainId: number]: string } = {
  [ChainId.FANTOM]: FTM_GRAPH_URL,
  [ChainId.BIT_TORRENT_MAINNET]: BTTC_GRAPH_URL,
  [ChainId.EON]: EON_GRAPH_URL,
  [ChainId.BERA_TESTNET]: BERA_TESTNET_GRAPH_URL,
  [ChainId.SONIC_TESTNET]: SONIC_TESTNET_GRAPH_URL,
  [ChainId.GOAT_TESTNET]: GOAT_TESTNET_GRAPH_URL,
}

const httpLink = new HttpLink({ uri: V2_SUBGRAPH_URL_BY_CHAIN[DEFAULT_CHAIN_ID] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId: any = store.getState().application.chainId

  operation.setContext(() => ({
    uri:
      chainId && V2_SUBGRAPH_URL_BY_CHAIN[chainId]
        ? V2_SUBGRAPH_URL_BY_CHAIN[chainId]
        : V2_SUBGRAPH_URL_BY_CHAIN[DEFAULT_CHAIN_ID],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})
