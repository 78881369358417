import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { IHasTradeProperties, QuoteMethod } from 'state/routing/types'
import { ThemedText } from 'theme'

export default function RouterLabel({ trade }: { trade: IHasTradeProperties }) {
  const isGoat = DEFAULT_CHAIN_ID === ChainId.GOAT_TESTNET
  const routerName = isGoat ? 'GoatSwap Client' : 'Spookyswap client'
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE || trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return <ThemedText.BodySmall>{routerName}</ThemedText.BodySmall>
  }
  return <ThemedText.BodySmall>Best Trade API</ThemedText.BodySmall>
}
