import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { TraceEvent } from 'analytics'
import {
  BRAND_ASSETS_LINK,
  BUG_LINK,
  DISCORD_LINK,
  DOCS_LINK,
  FAQS_LINK,
  FORMS_LINK,
  FORUM_LINK,
  GITHUB_LINK,
  MEDIUM_LINK,
  REDDIT_LINK,
  SITE_NAME,
  TELEGRAM_LINK,
  TWITTER_LINK,
  VOTE_LINK,
} from 'constants/misc'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { TelegramIcon } from 'nft/components/icons'
import styled from 'styled-components'
import { BREAKPOINTS, ExternalLink, StyledRouterLink } from 'theme'
import { FooterLogo } from 'theme/assets'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { isV3Disabled, isV3FarmDisabled } from 'utils/env'

import { DiscordIcon, GithubIcon, TwitterIcon } from './Icons'

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  max-width: 1440px;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }

  background-color: #121122bf;
  backdrop-filter: blur(8px);
  padding: 32px;
  border-radius: 20px;
`

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoSectionLeft = styled(LogoSection)`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`

const LogoSectionBottom = styled(LogoSection)`
  display: flex;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const StyledLogo = styled.img`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 0 0;
`

const SocialLink = styled.a`
  display: flex;
`

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content;
  margin: 20px 0 0 0;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    margin: 0;
  }
`

const LinkGroupTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 535;
`

const ExternalTextLink = styled(ExternalLink)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const TextLink = styled(StyledRouterLink)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const Copyright = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.neutral3};
`

const LogoSectionContent = () => {
  const isDarkMode = useIsDarkMode()
  return (
    <>
      <StyledLogo src={FooterLogo} />
      <SocialLinks>
        <SocialLink href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
          <DiscordIcon size={32} />
        </SocialLink>
        <TraceEvent
          events={[BrowserEvent.onClick]}
          name={SharedEventName.ELEMENT_CLICKED}
          element={InterfaceElementName.TWITTER_LINK}
        >
          <SocialLink href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
            <TwitterIcon size={32} />
          </SocialLink>
        </TraceEvent>
        <SocialLink href={GITHUB_LINK} target="_blank" rel="noopener noreferrer">
          <GithubIcon size={32} />
        </SocialLink>
        <SocialLink href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <TelegramIcon height={38} width={38} />
        </SocialLink>
      </SocialLinks>
      <Copyright>
        © {new Date().getFullYear()} {SITE_NAME} Team
      </Copyright>
    </>
  )
}

export const AboutFooter = () => {
  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  const v3Disabled = isV3Disabled()
  const poolsUrl = v3Disabled ? '/pools/v2' : '/pools'
  const farmsUrl = isV3FarmDisabled() ? '/farms/v2' : '/farms/v3'
  return (
    <Footer>
      <LogoSectionLeft>
        <LogoSectionContent />
      </LogoSectionLeft>

      <FooterLinks>
        <LinkGroup>
          <LinkGroupTitle>App</LinkGroupTitle>
          <TextLink to="/swap">Swap</TextLink>
          {!shouldDisableNFTRoutes && <TextLink to="/tokens">Tokens</TextLink>}
          {!shouldDisableNFTRoutes && <TextLink to="/nfts">NFTs</TextLink>}
          <TextLink to={poolsUrl}>Liquidity</TextLink>
          <TextLink to={farmsUrl}>Farms</TextLink>
        </LinkGroup>
        <LinkGroup>
          <LinkGroupTitle>Community</LinkGroupTitle>
          <ExternalTextLink href={DISCORD_LINK}>Discord</ExternalTextLink>
          <ExternalTextLink href={TWITTER_LINK}>Twitter</ExternalTextLink>
          <ExternalTextLink href={TELEGRAM_LINK}>Telegram</ExternalTextLink>
          {REDDIT_LINK && <ExternalTextLink href={REDDIT_LINK}>Reddit</ExternalTextLink>}
        </LinkGroup>
        <LinkGroup>
          <LinkGroupTitle>Info</LinkGroupTitle>
          <ExternalTextLink href={TWITTER_LINK}>News</ExternalTextLink>
          <ExternalTextLink href={MEDIUM_LINK}>Medium</ExternalTextLink>
          <ExternalTextLink href={DOCS_LINK}>Docs</ExternalTextLink>
          <ExternalTextLink href={FAQS_LINK}>FAQs</ExternalTextLink>
          <ExternalTextLink href={DISCORD_LINK}>Help</ExternalTextLink>
          <ExternalTextLink href={BRAND_ASSETS_LINK}>Media Kit</ExternalTextLink>
          <ExternalTextLink href={TWITTER_LINK}>Contact Us</ExternalTextLink>
        </LinkGroup>
        <LinkGroup>
          <LinkGroupTitle>Participate</LinkGroupTitle>
          {VOTE_LINK && <ExternalTextLink href={VOTE_LINK}>Vote</ExternalTextLink>}
          {FORUM_LINK && <ExternalTextLink href={FORUM_LINK}>Forum</ExternalTextLink>}
          <ExternalTextLink href={FORMS_LINK}>Apply for Collab</ExternalTextLink>
          <ExternalTextLink href={FORMS_LINK}>Apply for Token Listing</ExternalTextLink>
          <ExternalTextLink href={FORMS_LINK}>Apply for Farm</ExternalTextLink>
          <ExternalTextLink href={BUG_LINK}>Bug Bounty</ExternalTextLink>
        </LinkGroup>
      </FooterLinks>

      <LogoSectionBottom>
        <LogoSectionContent />
      </LogoSectionBottom>
    </Footer>
  )
}
