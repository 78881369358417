import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { ChainId, Fraction } from '@uniswap/sdk-core'
import { NATIVE_NAMES_BY_ID } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import Row from 'components/Row'
import { DeltaArrow, formatDelta } from 'components/Tokens/TokenDetails/Delta'
import { BIG_INT_ZERO, DEFAULT_CHAIN_ID } from 'constants/misc'
import { nativeOnChain } from 'constants/tokens'
import { useCurrency } from 'hooks/Tokens'
import { useAtomValue } from 'jotai/utils'
import JSBI from 'jsbi'
import { EmptyWalletModule } from 'nft/components/profile/view/EmptyWalletContent'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { EllipsisStyle, ThemedText } from 'theme'
import { ToDecimalsExpandedOrDefault } from 'utils/currency'
import { formatNumber, NumberType } from 'utils/formatNumbers'
import { splitHiddenTokens } from 'utils/splitHiddenTokens'

import { useToggleAccountDrawer } from '../..'
import { hideSmallBalancesAtom } from '../../SmallBalanceToggle'
import { ExpandoRow } from '../ExpandoRow'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'

export type TokenBalanceCovalent = {
  contract_name: string
  contract_ticker_symbol: string
  contract_decimals: number
  balance: string
  quote: number
  quote_rate: number
  quote_rate_24h: number
  contract_address: string
}

export const CHAIN_MAP: Record<number, string> = {
  250: 'fantom-mainnet',
  199: 'bittorent-mainnet',
  7332: 'horizen-eon-mainnet',
  80084: 'bera-testnet',
  64165: 'sonic-testnet',
  48816: 'goat-testnet',
  137: 'matic-mainnet',
  10: 'optimism-mainnet',
  8453: 'base-mainnet',
  56: 'bsc-mainnet',
  43114: 'avalanche-mainnet',
}

const FetchPortoflioFromCovalent = async (account: string, chainId: number | undefined) => {
  const covalentApiKey = process.env.REACT_APP_COVALENT_API_KEY
  //bittorent not supported
  if (!covalentApiKey || chainId === ChainId.BIT_TORRENT_MAINNET) {
    return
  }
  const chaintag = CHAIN_MAP[chainId ?? DEFAULT_CHAIN_ID]
  const covalentApiUrl = `https://api.covalenthq.com/v1/${chaintag}/address/${account}/balances_v2/?key=${covalentApiKey}&no-nft-fetch=true&no-spam=true`

  const res = await fetch(covalentApiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (res.status !== 200) {
    console.log(`Covalent API errored with status ${res.statusText}`)
    return
  }
  const json = await res.json()
  return json
}

export default function Tokens({ account }: { account: string }) {
  const { chainId } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [tokens, setTokens] = useState<
    { visibleTokens: TokenBalanceCovalent[]; hiddenTokens: TokenBalanceCovalent[] } | undefined
  >({ visibleTokens: [], hiddenTokens: [] })

  const hideSmallBalances = useAtomValue(hideSmallBalancesAtom)
  const [showHiddenTokens, setShowHiddenTokens] = useState(false)

  const SUPPORTED_COVALENT_CHAINS = useMemo(() => [ChainId.FANTOM, ChainId.EON], [])

  const {
    data: covalentData,
    error: covalentError,
    isLoading: covalentLoading,
  } = useQuery(
    ['portfolio', account, chainId],
    async () => {
      const data = await FetchPortoflioFromCovalent(account, chainId)
      return data.data.items
    },
    {
      enabled: !!account && !!chainId && SUPPORTED_COVALENT_CHAINS.includes(chainId),
      refetchInterval: 300000,
      staleTime: 300000,
      cacheTime: 300000,
    }
  )
  const tokenBalanceCovalent = covalentData as TokenBalanceCovalent[] | undefined

  useEffect(() => {
    const fetchData = async () => {
      const result = await splitHiddenTokens(tokenBalanceCovalent ?? [], chainId, { hideSmallBalances })
      setTokens(result as any)
    }
    fetchData()
  }, [chainId, hideSmallBalances, tokenBalanceCovalent])

  if (!covalentData && !covalentError && covalentLoading) {
    return <PortfolioSkeleton />
  }

  if (
    (tokens?.visibleTokens.length === 0 && tokens?.hiddenTokens.length === 0) ||
    chainId === ChainId.BIT_TORRENT_MAINNET
  ) {
    // TODO: consider launching moonpay here instead of just closing the drawer
    return <EmptyWalletModule type="token" onNavigateClick={toggleWalletDrawer} />
  }

  const toggleHiddenTokens = () => setShowHiddenTokens((showHiddenTokens) => !showHiddenTokens)

  return (
    <PortfolioTabWrapper>
      {tokens && tokens.visibleTokens.length > 0
        ? tokens.visibleTokens
            .filter((t) => JSBI.GT(JSBI.BigInt(t.balance), BIG_INT_ZERO))
            .map(
              (tokenBalance) =>
                tokenBalance.contract_name && <TokenRowCovalent key={tokenBalance.contract_address} {...tokenBalance} />
            )
        : null}
      <ExpandoRow isExpanded={showHiddenTokens} toggle={toggleHiddenTokens} numItems={tokens?.hiddenTokens.length ?? 0}>
        {tokens && tokens.hiddenTokens.length > 0 && showHiddenTokens
          ? tokens.hiddenTokens
              .filter((t) => JSBI.GT(JSBI.BigInt(t.balance), BIG_INT_ZERO))
              .map((tokenBalance) => <TokenRowCovalent key={tokenBalance.contract_address} {...tokenBalance} />)
          : null}
      </ExpandoRow>
    </PortfolioTabWrapper>
  )
}

const TokenBalanceText = styled(ThemedText.BodySecondary)`
  ${EllipsisStyle}
`
const TokenNameText = styled(ThemedText.SubHeader)`
  ${EllipsisStyle}
`

function TokenRowCovalent({ balance, quote, contract_address, quote_rate, quote_rate_24h, contract_decimals }: any) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const defaultDecimals = ToDecimalsExpandedOrDefault(contract_decimals)
  const balance_normalized = Number(new Fraction(balance, defaultDecimals).toFixed(6))
  const pricePercentChange = quote_rate_24h ? ((quote_rate - quote_rate_24h) / quote_rate_24h) * 100 : 0
  const currency = useCurrency(contract_address, chainIdOrDefault)
  const nativeCurrencyInfo = NATIVE_NAMES_BY_ID[chainIdOrDefault]
  const nativeCurrency = nativeOnChain(chainIdOrDefault)

  const currencyAddressIndex = 2
  const effectiveCurrency = useMemo(() => {
    if (nativeCurrencyInfo[currencyAddressIndex] == contract_address) {
      return nativeCurrency
    } else {
      return currency
    }
  }, [contract_address, currency, nativeCurrency, nativeCurrencyInfo])

  if (!effectiveCurrency) {
    return null
  }

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_TOKEN_ROW}
    >
      <PortfolioRow
        left={<PortfolioLogo chainId={chainIdOrDefault} currencies={[effectiveCurrency]} size="40px" />}
        title={<TokenNameText>{effectiveCurrency?.name}</TokenNameText>}
        descriptor={
          <TokenBalanceText>
            {formatNumber({ input: balance_normalized, type: NumberType.TokenNonTx })} {effectiveCurrency?.symbol}
          </TokenBalanceText>
        }
        right={
          quote && (
            <>
              <ThemedText.SubHeader>
                {formatNumber({
                  input: quote,
                  type: NumberType.PortfolioBalance,
                })}
              </ThemedText.SubHeader>
              <Row justify="flex-end">
                <DeltaArrow delta={pricePercentChange} />
                <ThemedText.BodySecondary>{formatDelta(pricePercentChange)}</ThemedText.BodySecondary>
              </Row>
            </>
          )
        }
      />
    </TraceEvent>
  )
}
