import { InterfaceElementName } from '@uniswap/analytics-events'
import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID, SITE_NAME } from 'constants/misc'
import { DollarSign, Terminal } from 'react-feather'
import styled from 'styled-components'
import { lightTheme } from 'theme/colors'

import { isV3Disabled } from '../../utils/env'
import darkArrowImgSrc from './images/aboutArrowDark.png'
import lightArrowImgSrc from './images/aboutArrowLight.png'
import darkDollarImgSrc from './images/aboutDollarDark.png'
import darkTerminalImgSrc from './images/aboutTerminalDark.png'
import nftCardImgSrc from './images/nftCard.png'
import swapCardImgSrc from './images/swapCard.png'

export const MAIN_CARDS = [
  {
    to: '/swap',
    title: 'Swap tokens',
    description: `Buy, sell, and explore tokens on ${SITE_NAME}.`,
    cta: 'Trade Tokens',
    darkBackgroundImgSrc: swapCardImgSrc,
    lightBackgroundImgSrc: swapCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  },
  {
    to: '/nfts',
    title: 'Trade NFTs',
    description: 'Buy and sell NFTs across marketplaces to find more listings at better prices.',
    cta: 'Explore NFTs',
    darkBackgroundImgSrc: nftCardImgSrc,
    lightBackgroundImgSrc: nftCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_NFTS_CARD,
  },
]

const StyledCardLogo = styled.img`
  min-width: 20px;
  min-height: 20px;
  max-height: 48px;
  max-width: 48px;
`

const V3Disabled = isV3Disabled()
const poolURL = V3Disabled ? '/pools/v2' : '/pools'
const isGoat = DEFAULT_CHAIN_ID === ChainId.GOAT_TESTNET
const cardTextToken = isGoat ? 'GOAT' : 'BOO'
const stakeURL = isGoat ? '/stake-goat' : '/stake-boo'

export const MORE_CARDS_FTM = [
  {
    // TODO: Point to stakeBoo v2 page
    to: stakeURL,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    title: 'Stake ',
    description: `Stake ${cardTextToken} for x${cardTextToken} to claim your portion of trade fees from all DEX trades.`,
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    cta: 'Stake now',
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: poolURL,
    title: 'Earn',
    description: `Provide liquidity to pools on ${SITE_NAME} and earn fees on swaps.`,
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: '/bridge',
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Bridge',
    description: 'Bridge and Cross-Chain swap tokens using bridges provided by our partners.',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Bridge now',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]

export const MORE_CARDS_BTT = [
  {
    // TODO: Point to stakeBoo v2 page
    to: '#/farms/v3',
    external: true,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    title: 'Farms',
    description: 'Stake Your Liquidity Tokens Here And Earn Rewards!.',
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    cta: 'Stake now',
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: poolURL,
    title: 'Earn',
    description: `Provide liquidity to pools on ${SITE_NAME} and earn fees on swaps.`,
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: 'https://app.bt.io/bridge',
    external: true,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Bridge',
    description: 'Bridge and Cross-Chain swap tokens using bridges provided by our partners.',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Bridge now',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]

export const MORE_CARDS_EON = [
  {
    to: poolURL,
    title: 'Earn',
    description: `Provide liquidity to pools on ${SITE_NAME} and earn fees on swaps.`,
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: 'https://docs.goatswap.fi/',
    external: true,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Docs & Help',
    description: 'Got any questions or want to learn more?',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Check out our docs',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]
